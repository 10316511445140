import React from 'react'

const CitationGenerator = () => {
  return (
    <div>
      citation
    </div>
  )
}

export default CitationGenerator
