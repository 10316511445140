import React from "react";

const CardQuiz = () => {
  

  return (
    <div className="quiz-page">
      <h1>Quiz</h1>
      
    </div>
  );
};

export default CardQuiz;
