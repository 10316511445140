import React from "react";
import "./about.css";
// import aboutus from "../../../assets/aboutus.jpg"



const About = () => {
  return (
    <section id="about">
      <div className="about-container">
        <div className="container">
          <div className="row about-row ">
            {/* <div className="col-lg-6 aboutus-image ">
              <img src={aboutus} alt="" />
              <div className="team-images">
                <div className="flip-3d">
                  <figure>
                    <img src={razavi} alt="Niloofar Razavi" loading="lazy" />
                    <figcaption>نیلوفر رضوی</figcaption>
                  </figure>
                </div>

                <div className="flip-3d">
                  <figure>
                    <img src={vosouq} alt="Elahe Vosouq" loading="lazy" />
                    <figcaption>الهه وثوق</figcaption>
                  </figure>
                </div>

                <div className="flip-3d">
                  <figure>
                    <img
                      src={shamsizadeh}
                      alt="Dena Shamsizadeh"
                      loading="lazy"
                    />
                    <figcaption>دنا شمسی‌زاده</figcaption>
                  </figure>
                </div>
              </div>
              <div className="team-images">
                <div className="flip-3d">
                  <figure>
                    <img src={sinaiyan} alt="Paria Sinaiyan" loading="lazy" />
                    <figcaption>پریا سینائیان</figcaption>
                  </figure>
                </div>
                <div className="flip-3d">
                  <figure>
                    <img
                      src={mirmohamadi}
                      alt="Nina Mirmohammadi"
                      loading="lazy"
                    />
                    <figcaption>نینا میرمحمدی</figcaption>
                  </figure>
                </div>
                <div className="flip-3d">
                  <figure>
                    <img src={baqeri} alt="Mahsa Baqeri" loading="lazy" />
                    <figcaption>مهسا باقری</figcaption>
                  </figure>
                </div>
              </div>
            </div> */}
            <div className=" about-description">
              <h2>دربارهٔ ما</h2>
              <p>
                مان تحقیق و نظر آویس (متن آویس)، از سال ۱۴۰۱ با هدف سازماندهی و
                فراهم کردن دسترسی آسان به محتوای پیچیدۀ متنی در حوزهٔ تاریخ و
                تئوری معماری و هنر تأسیس شد. تاریخ و تئوری در این حوزه‌ها، اعم
                از معماری، شهرسازی، معماری منظر و حتی درک عمومی هنر عمیقاً متکی
                به مجموعه‌های دانشی مفصل است. این مجموعه‌ها بیشتر در قالب کتب
                مرجع تنظیم شده‌ و با الگوهای چابک جستجو در مقالات، که امروز در
                دسترس هستند، هم‌خوانی ندارند. گروه بنیان‌گذاران متن آویس، با
                همکاری پژوهشگران ثابت و پاره‌وقت، ساماندهی محتوای مفصل و غنیِ
                این حوزه‌های اندیشه را با تکیه بر تجارب پژوهشی، از یک دهه پیش
                آغاز کرده و هم‌اکنون به مرحلهٔ اتصال با مخاطبان و پژوهشگران
                رسانده است. تاکنون ساماندهی مباحث در این حوزه با ایجاد شبکه
                معنایی (Semantic Network) در قالب هستان‌شناسی (ontology) و
                سینتوپیکون (Syntopicon) انجام شده است. به علاوه، تقویم توسعهٔ
                مفاهیم با توجه به محورهای موضوعی تعیین شده از سوی کمیتۀ علمی
                مجموعه و در پاسخ به نیازهای پژوهشی مخاطبین طرح و برنامه‌ریزی
                می‌شود.
              </p>
              {/* <ul className="about-summarybox">
                <li>
                  <HiUserGroup />
                  <p>کاربر</p>
                  <p>+ ۲۰۰</p>
                </li>
                <li>
                  <BsAwardFill />
                  <p>تجربه</p>
                  <p>+ ۵ سال</p>
                </li>
                <li>
                  <SiBookstack />
                  <p>پروژه</p>
                  <p>+ ۲۰</p>
                </li>
              </ul> */}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default About;
