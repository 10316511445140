import React from "react";
import { Link } from "react-router-dom";
import PersianDate from "persian-date";
import "./Posts.css";

const Posts = ({ posts }) => {
  return (
    <div className="posts-container">
      {posts?.map((post) => (
        <div className="posts-box" key={post.id}>
          <div className="posts-img">
            <Link to={`/article/${post.slug}`}>
              <img src={post.coverPhoto.url} alt="" />
            </Link>
          </div>
          <div className="posts-text">
            <div className="category-time">
              {post.categories?.map((category) => (
                <Link
                  to={`/posts/${category.slug}`}
                  key={category.id}
                  className="post-category"
                  style={{ background: category.color.css }}
                >
                  {category.name}
                </Link>
              ))}
            </div>
            <Link to={`/article/${post.slug}`}>
              <h3>{post.title}</h3>
            </Link>
            <p className="author">{post.author}</p>
            <p className="excerpt">{post.description}</p>
            <div className="published-time">{formatDate(post.updatedAt)}</div>
          </div>
        </div>
      ))}
    </div>
  );
};

const formatDate = (dateString) => {
  const datePart = dateString.split("T")[0];
  const dateObject = new Date(datePart);
  if (isNaN(dateObject.getTime())) {
    return "";
  }
  const persianDate = new PersianDate(dateObject);
  return persianDate.format(" DD MMMM YYYY", "fa");
};

export default Posts;
