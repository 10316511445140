import React from "react";
import { Outlet } from "react-router-dom";
import BlogsHeader from "../components/blogs-components/BlogsHeader"
import "./BlogsLayout.css"

const BlogsLayout = () => {
  return (
    <div>
      <BlogsHeader />

      <main className="home-posts">
        {/* the pages included in the route are added here */}
        <Outlet />
      </main>

    </div>
  );
};

export default BlogsLayout;
