import React from 'react'
import "./ComingSoon.css"

export default function ComingSoon() {
  return (
    <div className=' coming-soon'>
        <div className='container'>

      <h1>به زودی ...</h1>

        </div>
     

    </div>
  )
}
