import React, { useState, useEffect } from "react";
import { graphcms, QUERY_SLUG_CATEGORIES } from "../../Graphql/Queries";
import BlogsNavLinks from "../blogs-components/BlogsNavLinks";
import "./BlogsHeader.css";
import { TbPencilMinus } from "react-icons/tb";
import BlogSearch from "./BlogSearch";

const BlogsHeader = () => {
  const [categories, setCategories] = useState([]);
  
  useEffect(() => {
    graphcms
      .request(QUERY_SLUG_CATEGORIES)
      .then((res) => setCategories(res.categories));
  }, []);

  // Specify the categories you want to display by their name
  const specificCategories = categories.filter(category =>
    ["یادداشت بلند", "یادداشت کوتاه"].includes(category.name)
  );

  // Exclude the specific categories from the full list
  const otherCategories = categories.filter(category =>
    !["یادداشت بلند", "یادداشت کوتاه"].includes(category.name)
  );

  return (
    <div className="blogs-header">
      <h2>
        یادداشت
        <TbPencilMinus />
      </h2>

     
      <div className="specific-categories">
        {/* Pass showAllLink to show the "همه یادداشت‌ها" link */}
        <BlogsNavLinks categories={specificCategories} showAllLink={true} />
      </div>
      <BlogSearch />
      <div className="other-categories">
      {/* Display other categories without the "همه یادداشت‌ها" link */}
      <h3>موضوعات</h3>
      <BlogsNavLinks categories={otherCategories} />
      </div>
    </div>
  );
};

export default BlogsHeader;
