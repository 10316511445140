import React from "react";
import { Link } from "react-router-dom";
import "./ImageCardsHome.css";
import SwiperCard from "../../../../components/swiper-card/SwiperCard";
import Card from "react-bootstrap/Card";

import prehistoryThumbnail from "../../../../assets/prehistory.png";
import mesopotamiaThumbnail from "../../../../assets/mesopotamia.png";
import egyptThumbnail from "../../../../assets/egypt.png";
import aegeanThumbnail from "../../../../assets/aegean.png";
import greeceThumbnail from "../../../../assets/greece-thumbnail.png";
import romeThumbnail from "../../../../assets/rome.png";
import christianityThumbnail from "../../../../assets/christianity.png";
import byzantineThumbnail from "../../../../assets/byzantine.png";
import gothicTumbnail from "../../../../assets/gothicThumbnail.png";
import renaissanceThumbnail from "../../../../assets/renaissanceThumbnail.png";
import baroqueThumbnail from "../../../../assets/baroque.png";
import rococoThumbnail from "../../../../assets/rococo.jpg";

import aboutpic from "../../../../assets/flashcard-about.png";
import fourchoice from "../../../../assets/fourchoice.png";
import truefalse from "../../../../assets/truefalse.png";
import answerimage from "../../../../assets/answerimage.png";
import match from "../../../../assets/match.png";



const cardData = [
  {
    imgSrc: prehistoryThumbnail,
    title: "پیش از تاریخ",
  },
  {
    imgSrc: mesopotamiaThumbnail,
    title: "بین‌النهرین",
  },
  {
    imgSrc: egyptThumbnail,
    title: "مصر",
  },
  {
    imgSrc: aegeanThumbnail,
    title: "تمدن اژه",
  },
  {
    imgSrc: greeceThumbnail,
    title: "یونان",
  },
  {
    imgSrc: romeThumbnail,
    title: "اتروسک و روم",
  },
  {
    imgSrc: christianityThumbnail,
    title: "صدر مسیحیت",
  },
  {
    imgSrc: byzantineThumbnail,
    title: "بیزانس",
  },
  {
    imgSrc: gothicTumbnail,
    title: "قرون وسطی",
  },
  {
    imgSrc: renaissanceThumbnail,
    title: "رنسانس",
  },
  {
    imgSrc: baroqueThumbnail,
    title: "باروک",
  },
  {
    imgSrc: rococoThumbnail,
    title: "روکوکو",
  },
];

function ImageCards() {
  return (
    <div className="image-card">
      <div className="imagecard-overview">
        <SwiperCard />
      </div>

      <div className="imagecard-subjects">
        <h2>مباحث</h2>
        <div className="imagecard-subjects-wrapper">
          <div className="subjects-thumbnail">
            <div className="row">
              {cardData.map((card, index) => (
                <div key={index} className="col-md-2">
                  <Card style={{ width: "9rem" }} className="border-0 rounded">
                    <Card.Img variant="top" src={card.imgSrc} loading="lazy" />
                    <Card.Body>
                      <Card.Title>{card.title}</Card.Title>
                      {/* <Button variant="primary">Go somewhere</Button> */}
                    </Card.Body>
                  </Card>
                </div>
              ))}
            </div>
          </div>

          <div className="tag-search-wrapper">
            <h2 className="mb-3">برچسب‌ها</h2>
            <form>
              <input type="text" placeholder="جستجو"></input>
            </form>
            <button>جستجو</button>
          </div>
        </div>
      </div>
      <div className="imagecard-about">
        <div className="imagecard-about-container">
          <div className="row">
            <div className="col-md-6">
              <img src={aboutpic} alt="flashcards" />
            </div>
            <div className="col-md-6 about-text-box">
              <h2 className="mb-4">شیوهٔ یادگیری با یادبرگ</h2>
              <p>
              ما باور داریم که یادبرگ‌ روشی آسان، سریع و موثر برای تثبیت مفاهیم کلیدی در ذهن به شکل شخصی‌سازی‌شده و متناسب با توانایی‌ها و دانسته‌های شماست.
با توجه به اهمیت "تصویر" در شناخت و تحلیل پدیده‌ها در حوزه‌های هنر و آبادانی، تنظیم و طراحی یادبرگ‌ها مبتنی بر به‌کارگیری تصاویر برای ماندگار شدن مفاهیم است. موضوع یادبرگ‌ها، بر اساس مفاهیم و آثار پایه‌ و اصلی مطرح در تاریخ هنر، معماری، شهرسازی و معماری منظر، و به ترتیب زمانی است. به علاوه با سرفصل‌های آموزشی مرتبط با هر حوزه همخوانی دارد. 

برای استفاده از یادبرگ‌ها کافیست پس از ورود به حساب کاربری، وارد بخش یادبرگ‌ها شده و با کلیک بر روی هر تصویر  مطالب مختصر و مفید مرتبط با تصویر در سمت دیگر یادبرگ را مطالعه کنید. این نخستین گام در فرآیند یادگیری شماست.

گام بعدی و مهم‌تر در این فرآیند، محک زدن خودتان است. متن‌آویس در این زمینه نیز امکان ارزیابی و تکمیل یادگیری را برایتان فراهم کرده است. برای آزمودن خود، پس از خرید اشتراک مورد نظرتان، به بخش "آزمونک‌ها" دسترسی خواهید داشت. آزمونک‌ها، کوتاه و ساده و بر اساس مطالبی هستند که مطالعه کرده‌اید و در آن‌ها می‌توانید با چند کلیک ساده به سوالات پاسخ دهید. 

و اما گام سوم، تحلیل فرآیند یادگیریتان است. در این گام ما با استفاده از تحلیل یادبرگ‌های مطالعه شده و آزمونک‌ها، نقاط قوت و ضعف در هر موضوع، حوزه‌های علاقه‌مندی و حتی ارزیابی از جایگاهتان در میان سایر کاربران را در اختیارتان می‌گذاریم.

با ما آسان، ماندگار و مفید بیاموزید.
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="imagecard-quiz">
        <h2>آزمونک</h2>
        <ul>
          <li>
            <img src={fourchoice} alt="fourchoice question logo" />
            <Link to="/">چندگزینه‌ای</Link>
          </li>
          <li>
            <img src={answerimage} alt="guess the title question logo" />
            <Link>تصویر و عنوان</Link>
          </li>
          <li>
            <img src={match} alt="match question logo" />
            <Link>همانندی</Link>
          </li>
          <li>
            <img src={truefalse} alt="truefalse question logo" />
            <Link>صحیح و غلط</Link>
          </li>
        </ul>
      </div>
    </div>
  );
}

export default ImageCards;
