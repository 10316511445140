import React from "react";
import { Outlet, Link } from "react-router-dom";
import { BiBookBookmark } from "react-icons/bi";

import "./ImageCardLayout.css";

export default function ImageCardLayout() {
  return (
    <div className="imageCard-layout">
      <div className="imageCard-link-container">
        <nav>
          <ul>
            <li className="dropdown">
              <Link to="quiz">آزمونک</Link>
              <ul className="dropdown-content">
                <li>
                  <Link to="/quiz1">صحیح و غلط</Link>
                </li>
                <li>
                  <Link to="/quiz2">پازل</Link>
                </li>
                <li>
                  <Link to="/quiz3">تصویر و عنوان</Link>
                </li>
                <li>
                  <Link to="/quiz3">چند گزینه‌ای</Link>
                </li>
              </ul>
            </li>
            <li>
              <Link to="study">مطالعه</Link>
            </li>
            <li>
              <Link to="image-card-buy">خرید</Link>
            </li>
            {/* <li>
              <Link to="image-card-guide">راهنما</Link>
            </li> */}
            <li>
              <Link to="image-card-home">صفحهٔ اصلی یادبرگ‌ها</Link>
            </li>
            <li>
              <Link to="image-card-home" className="image-card-logo"><BiBookBookmark /></Link>
            </li>
          </ul>
        </nav>
      </div>

      <main>
        {/* the pages included in the route are added here */}
        <Outlet />
      </main>
    </div>
  );
}
