import { GraphQLClient, gql } from "graphql-request";
export const graphcms = new GraphQLClient(process.env.REACT_APP_API);

const category = `
id,
name,
slug,
color { css }
`;
const comment = `
id,
name,
comment
`;
const post = `
id,
title,
author,
slug,
coverPhoto { url },
content { html },
description,
createdAt,
updatedAt
`;

export const QUERY_SLUG_CATEGORIES = gql`
{
    categories() {
        name,
        slug
    }
}
`;
export const QUERY_POSTS = gql`

{
    posts(orderBy: updatedAt_DESC , first: 100) {
        ${post}
        categories(){
            ${category}
        }

    }
    
}
`;

export const QUERY_POSTS_BY_CATEGORY = gql`
  query GetPostsByCategory($slug: String!) {
    posts(
      orderBy: updatedAt_DESC,
      where: { categories_some: { slug: $slug } }
    ) {
      ${post}
      categories {
        ${category}
      }
    }
  }
`;

export const QUERY_SEARCH_POSTS = gql`
  query GetPostsByCategory($slug: String!) {
    posts(
      orderBy: updatedAt_DESC,
      where: { _search: $slug }
    ) {
      ${post}
      categories {
        ${category}
      }
    }
  }
`;
export const QUERY_ONE_POST = gql`
  query GetOnePost($slug: String!) {
    posts(where: {slug: $slug }) {
      ${post}
      categories() {
        ${category}
      }
      comments(orderBy: updatedAt_DESC){
        ${comment}
      }
    }
  }
`;
