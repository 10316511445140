import React from 'react'

export default function CardGuide() {
  return (
    <div>
      <h1>card guide</h1>
      <h1>card guide</h1>
      <h1>card guide</h1>
      <h1>card guide</h1>
      <h1>card guide</h1>
      <h1>card guide</h1>

    </div>
  )
}
