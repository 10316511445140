import React from 'react'
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

function SliderComponent({ settings, children }) {

  return (
    <Slider {...settings}>
    {React.Children.map(children, child => (
      <div>{child}</div>
    ))}
  </Slider>
  )
}

export default SliderComponent
