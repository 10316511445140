import React, { useState } from "react";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./Events.css";
import baghkhani1 from "../../assets/baghkhani1.jpg";
import baghkhani2 from "../../assets/baghkhani2.jpg";
import baghkhani3 from "../../assets/baggkhani3.jpg";
import baghkhani4 from "../../assets/baghkhani4.jpg";
import logoorange from "../../assets/logo-orange.png";
import logopeach from "../../assets/logo-peach.png";
import logoprimarydark from "../../assets/logo-primarydark.png";

import eventproposal from "../../assets/event-banner.png";
import { RxDoubleArrowDown } from "react-icons/rx";
import { MdPlayCircle } from "react-icons/md";
import SliderComponent from "../../components/SliderComponent";
import ModalComponent from "../../components/modal/ModalComponent";

function Events() {
  const [showModal, setShowModal] = useState(false);
  const [videoUrl, setVideoUrl] = useState("");

  const pastEventsSettings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 4,
    responsive: [
      {
        breakpoint: 853,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
        },
      },
    ],
  };

  const futureEventsSettings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 3,
    responsive: [
      {
        breakpoint: 853,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
        },
      },
    ],
  };

  //  data for past events
  const pastEventsData = [
    {
      title: "نشست باغ‌خوانی ۱",
      image: baghkhani1,
      url: "https://www.aparat.com/video/video/embed/videohash/loj5q72/vt/frame",
    },
    {
      title: "نشست باغ‌خوانی ۲",
      image: baghkhani2,
      url: "https://www.aparat.com/video/video/embed/videohash/kal02i3/vt/frame",
    },
    {
      title: "نشست باغ‌خوانی ۳",
      image: baghkhani3,
      url: "https://www.aparat.com/video/video/embed/videohash/mfd8z76/vt/frame",
    },
    { title: "نشست باغ‌خوانی ۴", image: baghkhani4,
      url: "https://www.aparat.com/video/video/embed/videohash/jctpj8q/vt/frame",
     },
  ];

  //  data for future events
  const futureEventsData = [
    { title: "  ", image: logoorange },
    { title: "", image: logoprimarydark },
    { title: "", image: logopeach },
  ];

  const handlePlayButtonClick = (url) => {
    setVideoUrl(url);
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
    setVideoUrl("");
  };

  return (
    <div className="events-page">
      <div className="event-header">
        <div className="event-header-content">
          <h1>رویدادها</h1>
          <div>
            <p>
              در شرکت متن‌آویس با هدف فراهم کردن فضایی پویا برای گفتگو و مباحثه
              در باب موضوعات مرتبط با حوزه‌های کاریِ تعریف‌شده، به صورت دوره‌ای،
              رویدادهایی علمی برگزار می‌کنیم. شرکت در این رویدادها برای عموم
              آزاد و رایگان است و به اقتضای مورد، در مکان‌هایی گوناگون، گاه
              حضوری و گاه مجازی، برگزار می‌شود. همهٔ رویدادها به صورت همزمان در
              شبکه‌های اجتماعی متن‌آویس پخش می‌شود و پس از جلسه، امکان تماشای
              نسخهٔ ضبط‌شدهٔ آن در وبگاه متن‌آویس (در همین صفحه) فراهم است. برای
              اطلاع از رویدادهای آینده می‌توانید به همین صفحه سر بزنید یا
              شبکه‌های اجتماعی متن‌آویس را دنبال کنید.
            </p>
          </div>
        </div>
      </div>
      <div className="all-events">
        <div className="past-events">
          <h2>رویدادهای گذشته</h2>
          <div className="baghkhani-series">
            <h3>سلسله‌نشست‌های باغ‌خوانی</h3>
            <SliderComponent settings={pastEventsSettings}>
              {pastEventsData.map((event, index) => (
                <div key={index} className="event-container">
                  <h4>{event.title}</h4>
                  <img src={event.image} alt="event poster" />
                  <button
                    className="play-button"
                    onClick={() => handlePlayButtonClick(event.url)}
                  >
                    <MdPlayCircle />{" "}
                  </button>
                </div>
              ))}
            </SliderComponent>
          </div>
        </div>
        <h2>رویدادهای آینده</h2>
        <div className="future-events">
          <SliderComponent settings={futureEventsSettings}>
            {futureEventsData.map((event, index) => (
              <div key={index} className="event-container">
                <h4>{event.title}</h4>
                <img src={event.image} alt="event poster" />
              </div>
            ))}
          </SliderComponent>
        </div>
        <div className="event-proposal">
          <div className="row ">
            <div className="col">
              <img src={eventproposal} alt="event proposal illustration" />
            </div>
            <div className="col">
              <h3>ایده‌ای برای نشست دارید؟</h3>
              <p>
                در شرکت متن‌آویس همواره برآنیم تا در کنار فعالیت‌های خود، فضایی
                برای مشارکت با اهالی حوزه‌های هنر و معماری فراهم کنیم. از این
                رو، می‌توانید رویدادهای پیشنهادی‌تان را با حمایت شرکت متن‌آویس
                برگزار کنید. در صورت تمایل به این همکاری، ایدهٔ برگزاری رویداد
                (از جمله سخنرانی، گفتگو، نشست، کارگاه) را با متن‌آویس در میان
                بگذارید.
              </p>
              <h4>
                <RxDoubleArrowDown />
                با ما تماس بگیرید
              </h4>
            </div>
          </div>
        </div>
      </div>

      <ModalComponent
        show={showModal}
        onClose={handleCloseModal}
        videoUrl={videoUrl}
      />
    </div>
  );
}

export default Events;
