import { React, useState, useRef } from "react";
import { NavLink } from "react-router-dom";

import "./projects.css";
// import network from "../../../assets/network.png";
import blog from "../../../assets/blog.jpg";
import projects from "../../../assets/projects.jpg";
import event from "../../../assets/event.jpg";
import pirnia from "../../../assets/pirnia.jpg";
import architecture from "../../../assets/architecture.jpg";

import page from "../../../assets/page.jpg";
import note from "../../../assets/note.jpg";
import { BiNetworkChart } from "react-icons/bi";
import { FaChalkboardTeacher } from "react-icons/fa";
import { BsReverseListColumnsReverse } from "react-icons/bs";
import { SlNotebook } from "react-icons/sl";
import { BsCalendar2Event } from "react-icons/bs";
import { HiPencilSquare } from "react-icons/hi2";

const data = [
  {
    id: "1",
    key: "1",
    title: "فعالیت‌های ما",
    text: "با ما بیشتر آشنا شوید",
    img: projects,
  },
  {
    id: "2",
    key: "2",
    title: "هستان‌شناسی‌ها",
    text: "در این پروژه، مجموعه‌ای از هستان‌شناسی‌ها گرد هم آمده است. هدف از توسعهٔ این مجموعه ایجاد شبکه‌ای از انواع پیوندهای معنایی میان مفاهیم کلیدی حوزه‌های مرتبط با هنر و آبادانی است. تا کنون سه هستان‌شناسی در حوزه‌های تاریخ معماری ایران، باغ ایرانی، تاریخ معماری منظر در شرکت متن‌آویس تهیه شده و برنامهٔ توسعهٔ این هستان‌شناسی‌ها و اتصال آن به هستان‌شناسی‌های موجود تعریف شده است. ",
    img: architecture,
  },
  {
    id: "3",
    key: "3",
    title: "یادداشت",
    text: "توضیح یادداشت",
    img: blog,
  },
  {
    id: "4",
    key: "4",
    title: "رویداد",
    text: "در شرکت متن‌آویس با هدف فراهم کردن فضایی پویا برای گفتگو و مباحثه در باب موضوعات مرتبط با حوزه‌های کاریِ تعریف‌شده، به صورت دوره‌ای، رویدادهایی علمی برگزار می‌کنیم. شرکت در این رویدادها برای عموم آزاد و رایگان است و به اقتضای مورد، در مکان‌هایی گوناگون، گاه حضوری و گاه مجازی، برگزار می‌شود. ",
    img: event,
  },
  {
    id: "5",
    key: "5",
    title: "پایگاه پیرنیا",
    text: "در این پروژه، مجموعه‌ای از از نوشته‌ها، درسگفتارها، واژگان و اصطلاحات محمدکریم پیرنیا و هم‌چنین نقد و بررسی‌های انجام‌شده دربارهٔ آرای او گردآوری شده است. ",
    img: pirnia,
  },
  {
    id: "6",
    key: "6",
    title: "سینتوپیکون",
    text: "توضیح سینتوپیکون",
    img: page,
  },
  {
    id: "7",
    key: "7",
    title: "درس‌افزار",
    text: "توضیح درس‌افزار",
    img: note,
  },
];

const Projects = () => {
  const [info, setInfo] = useState("1");
  const projectsRef = useRef(null);
  const scrollToProjects = () => {
    if (projectsRef.current) {
      projectsRef.current.scrollIntoView({ behavior: "smooth" });
    }
  };

  return (
    <section id="projects" ref={projectsRef}>
      <div className="projects-container">
        <div className="row">
          <div className="col-lg-4   ">
            {data.map(({ title, text, key, img }) => (
              <div key={key}>
                {info === key ? (
                  <div className="project-summary">
                    <div className="project-image">
                      <img src={img} key={key} alt="" loading="lazy" />
                    </div>
                    <div className="summary-box">
                      <h2>{title}</h2>
                      <p>{text}</p>
                    </div>
                  </div>
                ) : null}
              </div>
            ))}
          </div>
          <div className="col-lg-8 pt-5 ps-5">
            <div className="row row-container">
              <div className="col-lg-4 ">
                <div className="single-project  ">
                  <span className="project-icon">
                    <BiNetworkChart />
                  </span>
                  <h6>هستان‌شناسی‌ها</h6>
                  <button className="go-to-project">
                    <NavLink to="coming-soon">ورود به صفحهٔ پروژه</NavLink>
                  </button>
                  <button
                    className="summary-btn"
                    onClick={() => {
                      setInfo(data[1].key);
                      scrollToProjects();
                    }}
                  >
                    مختصری دربارهٔ پروژه
                  </button>
                </div>
              </div>
              <div className="col-lg-4">
                <div className="single-project  ">
                  <span className="project-icon">
                    <HiPencilSquare />
                  </span>
                  <h6>یادداشت‌ها</h6>
                  <button className="go-to-project">
                    <NavLink to="blogs">ورود به صفحهٔ پروژه</NavLink>
                  </button>
                  <button
                    className="summary-btn"
                    onClick={() => {
                      setInfo(data[2].key);
                      scrollToProjects();
                    }}
                  >
                    مختصری دربارهٔ پروژه
                  </button>
                </div>
              </div>
              <div className="col-lg-4">
                <div className="single-project border-0">
                  <span className="project-icon">
                    <BsCalendar2Event />
                  </span>
                  <h6>رویدادها</h6>
                  <button className="go-to-project">
                    <NavLink to="events">ورود به صفحهٔ پروژه</NavLink>
                  </button>
                  <button
                    className="summary-btn"
                    onClick={() => {
                      setInfo(data[3].key);
                      scrollToProjects();
                    }}
                  >
                    مختصری دربارهٔ پروژه
                  </button>
                </div>
              </div>
            </div>
            <div className="row row-container border-0">
              <div className="col-lg-4 ">
                <div className="single-project  ">
                  <span className="project-icon">
                    <FaChalkboardTeacher />
                  </span>
                  <h6>پایگاه پیرنیا</h6>
                  <button className="go-to-project">
                    <NavLink to="coming-soon">ورود به صفحهٔ پروژه</NavLink>
                  </button>
                  <button
                    className="summary-btn"
                    id="projects"
                    onClick={() => {
                      setInfo(data[4].key);
                      scrollToProjects();
                    }}
                  >
                    مختصری دربارهٔ پروژه
                  </button>
                </div>
              </div>
              <div className="col-lg-4">
                <div className="single-project  ">
                  <span className="project-icon">
                    <BsReverseListColumnsReverse />
                  </span>
                  <h6>سینتوپیکون</h6>
                  <button className="go-to-project">
                    <NavLink to="coming-soon">ورود به صفحهٔ پروژه</NavLink>
                  </button>
                  <button
                    className="summary-btn"
                    onClick={() => {
                      setInfo(data[5].key);
                      scrollToProjects();
                    }}
                  >
                    مختصری دربارهٔ پروژه
                  </button>
                </div>
              </div>
              <div className="col-lg-4">
                <div className="single-project border-0">
                  <span className="project-icon">
                    <SlNotebook />
                  </span>
                  <h6>درس‌افزار</h6>
                  <button className="go-to-project">
                    <NavLink to="image-card-home">ورود به صفحهٔ پروژه</NavLink>
                  </button>
                  <button
                    className="summary-btn"
                    onClick={() => {
                      setInfo(data[6].key);
                      scrollToProjects();
                    }}
                  >
                    مختصری دربارهٔ پروژه
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};
export default Projects;
