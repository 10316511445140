import React from "react";
import CreditCardSVG from "../../../../assets/hire.svg";
import { MdOutlineDensityLarge } from "react-icons/md";
import { MdOutlineDensityMedium } from "react-icons/md";
import { MdOutlineDensitySmall } from "react-icons/md";
import { RiVipDiamondLine } from "react-icons/ri";

import "./CardBuy.css";

export default function CardBuy() {
  return (
    <div className="CardBuy">
      <div className="price-page-container">
        <h1>خرید اشتراک</h1>
        <div className="price-guide">
          <p>
            با خرید اشتراک، متناسب با نیاز خود، می‌توانید از امکان ارزیابی و
            تحلیل فرآیند یادگیریتان در بخش "یادبرگ‌ها" استفاده کنید. در صورت
            اتمام اشتراکتان، به این امکانات دسترسی نخواهید داشت اما تمامی
            فعالیت‌ها در بخش یادبرگ‌ها در حساب کاربریتان تا شارژ مجدد اشتراک
            محفوظ می‌ماند.{" "}
          </p>
          <img src={CreditCardSVG} alt="Credit Card illustration" />
        </div>
        <div className="price-wrapper">
          <div className="price-box">
            <p className="icon">
              <MdOutlineDensityLarge />
            </p>
            <h2>اشتراک یک‌ ماهه</h2>
            <h3>۱۰.۰۰۰ تومان</h3>
            {/* <ul>
              <li>هزاران کتاب با قیمت یک کتاب</li>
              <li>مجلات و کتاب‌های الکترونیکی و صوتی</li>
              <li>دسترسی بدون اینترنت به آخرین کتاب دریافتی</li>
              <li>علامت‌گذاری‌ها با تمدید اشتراک</li>
            </ul> */}

            <button>خرید</button>
          </div>
          <div className="price-box">
            <p className="icon">
              <MdOutlineDensityMedium />
            </p>
            <h2>اشتراک سه ماهه</h2>
            <h3>۲۰.۰۰۰ تومان</h3>
            {/* <ul>
              <li>هزاران کتاب با قیمت یک کتاب</li>
              <li>مجلات و کتاب‌های الکترونیکی و صوتی</li>
              <li>دسترسی بدون اینترنت به آخرین کتاب دریافتی</li>
              <li>علامت‌گذاری‌ها با تمدید اشتراک</li>
            </ul>{" "} */}
            <button>خرید</button>
          </div>
          <div className="price-box">
            <p className="icon">
              <MdOutlineDensitySmall />
            </p>
            <h2>اشتراک شش ماهه</h2>
            <h3>۳۰.۰۰۰ تومان</h3>
            {/* <ul>
              <li>هزاران کتاب با قیمت یک کتاب</li>
              <li>مجلات و کتاب‌های الکترونیکی و صوتی</li>
              <li>دسترسی بدون اینترنت به آخرین کتاب دریافتی</li>
              <li>علامت‌گذاری‌ها با تمدید اشتراک</li>
            </ul>{" "} */}
            <button>خرید</button>
          </div>
          <div className="price-box orange-box">
            <p className="icon">
              <RiVipDiamondLine />
            </p>
            <h2>اشتراک یک‌ ساله</h2>
            <h3>۶۰.۰۰۰ تومان</h3>
            {/* <ul>
              <li>هزاران کتاب با قیمت یک کتاب</li>
              <li>مجلات و کتاب‌های الکترونیکی و صوتی</li>
              <li>دسترسی بدون اینترنت به آخرین کتاب دریافتی</li>
              <li>علامت‌گذاری‌ها با تمدید اشتراک</li>
            </ul> */}
            <button>خرید</button>
          </div>
        </div>
      </div>
    </div>
  );
}
