import React from "react";
import "./NotFound.css";
import notfound from "../assets/notfound.svg";

const NotFound = () => {
  return (
    <div className="notfound">
      <img src={notfound} alt="Not Found" />
      <h2>صفحهٔ مورد نظر یافت نشد</h2>
      <a href="/">
        <button>بازگشت به خانه</button>
      </a>
    </div>
  );
};

export default NotFound;
