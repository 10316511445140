import React from "react";
import vosouq from "../../../assets/vosouq.jpg";
import razavi from "../../../assets/razavi.jpg";
import shamsizadeh from "../../../assets/shamsizadeh.jpg";
import sinaiyan from "../../../assets/sinaiyan.jpg";
import mirmohamadi from "../../../assets/mirmohamadi.jpg";
import baqeri from "../../../assets/baqeri.jpg";
import { FaLinkedin } from "react-icons/fa";
import { HiOutlineMailOpen } from "react-icons/hi";

import "./Team.css";
import { Link } from "react-router-dom";

const Team = () => {
  return (
    <section id="team">
      <div className="our-team-section">
        <h2>تیم ما</h2>
        <h3>با اعضای تیم ما آشنا شوید</h3>

        <div className="teamcontainer">
          <div className="row">
            <div className="col">
              <div className="team-image">
                <img src={razavi} alt="Niloofar Razavi" />
              </div>
            </div>
            <div className="col">
              <div className="team-info blue-box">
                <h2>نیلوفر رضوی</h2>
                <p className="team-role">رئیس هیئت مدیره</p>
                <p>استادیار دانشگاه شهید بهشتی</p>
                <div className="socials-links">
                  <Link>
                    <FaLinkedin />
                  </Link>
                  <Link>
                    <HiOutlineMailOpen />
                  </Link>
                </div>
              </div>
            </div>
            <div className="col">
              <div className="team-image">
                <img src={vosouq} alt="Elahe Vosouqi" />
              </div>
            </div>
            <div className="col">
              <div className="team-info">
                <h2>الهه وثوقی</h2>
                <p className="team-role">مدیر عامل</p>
                <p>کارشناس ارشد معماری منظر</p>
                <div className="socials-links">
                  <Link>
                    <FaLinkedin />
                  </Link>
                  <Link>
                    <HiOutlineMailOpen />
                  </Link>
                </div>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col order-2">
              <div className="team-image">
                <img src={shamsizadeh} alt="Niloofar Razavi" />
              </div>
            </div>
            <div className="col order-1">
              {" "}
              <div className="team-info">
                <h2>دنا شمسی‌زاده</h2>
                <p className="team-role">مشاور علمی</p>
                <p>کارشناس ارشد مطالعات معماری ایران</p>
                <div className="socials-links">
                  <Link>
                    <FaLinkedin />
                  </Link>
                  <Link>
                    <HiOutlineMailOpen />
                  </Link>
                </div>
              </div>
            </div>
            <div className="col order-4">
              <div className="team-image">
                <img src={mirmohamadi} alt="Nina mirmohammadi" />
              </div>
            </div>
            <div className="col order-3">
              {" "}
              <div className="team-info">
                <h2>نینا میرمحمدی</h2>
                <p className="team-role">مدیر محتوا</p>
                <p>دکتری معماری</p>
                <div className="socials-links">
                  <Link>
                    <FaLinkedin />
                  </Link>
                  <Link>
                    <HiOutlineMailOpen />
                  </Link>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col">
              <div className="team-image">
                <img src={sinaiyan} alt="Paria Sianiyan" />
              </div>
            </div>
            <div className="col">
              <div className="team-info">
                <h2>پریا سینائیان</h2>
                <p className="team-role">طراح هویت بصری</p>
                <p>کارشناس گرافیک</p>
                <div className="socials-links">
                  <Link>
                    <FaLinkedin />
                  </Link>
                  <Link>
                    <HiOutlineMailOpen />
                  </Link>
                </div>
              </div>
            </div>
            <div className="col">
              <div className="team-image">
                <img src={baqeri} alt="Mahsa Baqeri" />
              </div>
            </div>
            <div className="col">
              <div className="team-info">
                <h2>مهسا باقری</h2>
                <p className="team-role">دستیار اجرایی</p>
                <p>کارشناس معماری</p>
                <div className="socials-links">
                  <Link>
                    <FaLinkedin />
                  </Link>
                  <Link>
                    <HiOutlineMailOpen />
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Team;
