import React, { useState } from "react";
import { NavLink } from "react-router-dom";
import logo from "../../assets/logo.png";
import "./NavigationBar.css";

// Bootstrap imports
import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import NavDropdown from "react-bootstrap/NavDropdown";

export default function NavigationBar() {
  const [expanded, setExpanded] = useState(false);

  const handleLinkClick = () => {
    setExpanded(false);
  };

  return (
    <Navbar expand="lg" fixed="top" className="navbar navbar-light bg-light" expanded={expanded}>
      <Container>
        <Navbar.Brand as={NavLink} to="/" onClick={handleLinkClick}>
          <img src={logo} width="40" height="40" alt="Matn Avis logo" />
        </Navbar.Brand>

        <Navbar.Toggle aria-controls="basic-navbar-nav" onClick={() => setExpanded(!expanded)} />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="ms-auto">
            <Nav.Link as={NavLink} to="/" className="order-md-3" onClick={handleLinkClick}>
              خانه
            </Nav.Link>
            <Nav.Link as={NavLink} to="login" className="order-md-2" onClick={handleLinkClick}>
              ورود | ثبت نام
            </Nav.Link>
            <NavDropdown title="پروژه‌ها" id="basic-nav-dropdown" className="order-md-1">
              <NavDropdown.Item
                as={NavLink}
                to="coming-soon"
                className="d-flex justify-content-end"
                onClick={handleLinkClick}
              >
                هستان‌شناسی‌ها
              </NavDropdown.Item>
              <NavDropdown.Item
                as={NavLink}
                to="coming-soon"
                className="d-flex justify-content-end"
                onClick={handleLinkClick}
              >
                سینتوپیکون
              </NavDropdown.Item>
              <NavDropdown.Item
                as={NavLink}
                to="coming-soon"
                className="d-flex justify-content-end"
                onClick={handleLinkClick}
              >
                پایگاه پیرنیا
              </NavDropdown.Item>
            </NavDropdown>
            <Nav.Link as={NavLink} to="image-card-home" className="order-md-1" onClick={handleLinkClick}>
              درس‌افزار
            </Nav.Link>
            <Nav.Link as={NavLink} to="blogs" className="order-md-1" onClick={handleLinkClick}>
              یادداشت
            </Nav.Link>
            <Nav.Link as={NavLink} to="events" className="order-md-1" onClick={handleLinkClick}>
              رویدادها
            </Nav.Link>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}
