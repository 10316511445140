import React from "react";
import { QUERY_POSTS_BY_CATEGORY } from "../../Graphql/Queries";
import Posts from "../../components/blogs-components/Posts";
import NotFound from "../../components/NotFound";
import useQueryPosts from "../../hooks/useQueryPosts";

const PostsByCategory = () => {
  const { posts, error, status } = useQueryPosts({
    query: QUERY_POSTS_BY_CATEGORY,
  });

  if (status === "success" && posts.length === 0) return <NotFound />;

  if (error) return <h2 style={{ textAlign: "center" }}>{error}</h2>;

  return <Posts posts={posts} />;
};

export default PostsByCategory;
