import React from "react";
import SliderComponent from "../../../../components/SliderComponent";
import "./CardStudy.css";
import fc1 from "../../../../assets/fc1.png";
import fc2 from "../../../../assets/fc2.png";

const CardStudy = () => {
  const flashcardsSettings = {
    dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    adaptiveHeight: true,
    arrows: true,
  };

  const SampleFlashcards = [
    {
      id: 1,
      image: fc1,
      description: "چتل هویوک: چشم‌انداز شهری و آتشفشان",
    },
    {
      id: 2,
      image: fc2,
      description: "تل هویوک: چشم‌انداز شهری و آتشفشان",
    },
  ];

  return (
    <div className="study-page">
      <div className="flashcards-container">
        <SliderComponent settings={flashcardsSettings}>
          {SampleFlashcards.map((flashcard, index) => (
            <div key={index} className="single-flashcard-container">
                <div className="flashcard-image">
                  <img src={flashcard.image} alt="" />
                </div>
                <div className="flashcard-text">
                  <p>{flashcard.description}</p>
                </div>
            </div>
          ))}
        </SliderComponent>
      </div>
    </div>
  );
};

export default CardStudy;
