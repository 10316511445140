import { React, useState } from "react";
import { useNavigate } from "react-router-dom";
import "./BlogSearch.css";


const BlogSearch = () => {
  const navigate = useNavigate();
  const [blogSearch, setBlogsearch] = useState("");

  function handleSearch(e) {
    e.preventDefault();
    setBlogsearch("");
    let value = blogSearch.trim().replace(/\s+/g, '-').toLocaleLowerCase();
    navigate(`/search/${value}`)

  }
  return (
    <form className="blog-search-bar" onSubmit={handleSearch}>
      <button type="submit">جستجو</button>
      <input
        type="search"
        name=""
        placeholder="کلیدواژه جستجو را وارد کنید"
        required
        value={blogSearch}
        onChange={(e) => setBlogsearch(e.target.value)}
      />
    </form>
  );
};

export default BlogSearch;
