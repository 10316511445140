import { React, useState } from "react";
import { useParams } from "react-router-dom";
import { graphcms, CREATE_COMMENT, PUBLISH_COMMENT } from "../../Graphql/Mutations";
import "./BlogComments.css";

const BlogComments = ({ comments }) => {
  const { slug } = useParams();

  const [name, setName] = useState("");
  const [comment, setComment] = useState("");
  const [save, setSave] = useState(true);
  const [loading, setLoading] = useState(false);
  async function handleComment(e) {
    e.preventDefault();
    const obj = { name, comment, slug };

    if (save) {
      localStorage.setItem("name", name);
    } else {
      localStorage.removeItem("name", name);
    }
    setLoading(true);
    const {createComment} = await graphcms.request(CREATE_COMMENT, obj);
    await graphcms.request(PUBLISH_COMMENT, {id: createComment?.id });
    setLoading(false);
  }

  return (
    <div className="blog-comments">
      <h3>دربارهٔ این یادداشت نظر دهید</h3>
      <form onSubmit={handleComment}>
        <input
          type="text"
          required
          placeholder="نامتان را وارد کنید"
          autoComplete="off"
          value={name}
          onChange={(e) => setName(e.target.value)}
        />
     
        <textarea
          cols="30"
          rows="10"
          placeholder="نظرتان را اینجا بنویسید"
          autoComplete="off"
          required
          value={comment}
          onChange={(e) => setComment(e.target.value)}
        ></textarea>
        <div className="check">
          <label htmlFor="chk">اطلاعات مرا به خاطر بسپار</label>
          <input
            type="checkbox"
            id="chk"
            onChange={(e) => setSave(e.target.checked)}
            checked={save}
          />
        </div>
        <button disabled={loading}>
          {loading ? "در حال ارسال..." : "ارسال نظر"}
        </button>
      </form>
      <div className="comments-container">
        {comments?.map((comment) => (
          <div className="content" key={comment.id}>
            <strong>
              <i></i>
              {comment.name}
            </strong>
            <p>{comment.comment}</p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default BlogComments;
