import {
  createBrowserRouter,
  createRoutesFromElements,
  Route,
  RouterProvider,
} from "react-router-dom";
import { useMyContext } from "./context/store";
import Loading from "./components/blogs-components/Loading";

// layouts
import RootLayout from "./layouts/RootLayout";
import ImageCardLayout from "./layouts/ImageCardLayout";
import BlogsLayout from "./layouts/BlogsLayout";

// pages
import Landing from "./pages/landing/Landing";
import Login from "./pages/login/Login";
import DarsafzaarHome from "./pages/projects/darsafzaar/darsafzaar-home/DarsafzaarHome";
import ComingSoon from "./pages/coming-soon/ComingSoon";
import ImageCardsHome from "./pages/projects/darsafzaar/darsafzaar-imagecards/ImageCardsHome";
import BlogsHome from "./pages/blogs/BlogsHome";
import PostsByCategory from "./pages/blogs/PostsByCategory";
import SearchResult from "./pages/blogs/SearchResult";
import Article from "./pages/blogs/Article";
import NotFound from "./components/NotFound";
import CardStudy from "./pages/projects/darsafzaar/darsafzaar-imagecards/CardStudy";
import CardQuiz from "./pages/projects/darsafzaar/darsafzaar-imagecards/CardQuiz";
import CardGuide from "./pages/projects/darsafzaar/darsafzaar-imagecards/CardGuide";
import CardBuy from "./pages/projects/darsafzaar/darsafzaar-imagecards/CardBuy";
import Events from "./pages/events/Events";


const router = createBrowserRouter(
  createRoutesFromElements(
    <Route element={<RootLayout />}>
      <Route path="/" element={<Landing />} />
      <Route path="/login" element={<Login />} />
      <Route path="/events" element={<Events />} />

      <Route path="/coming-soon" element={<ComingSoon />} />
      <Route path="*" element={<NotFound />} />
      
      {/* darsafzar pages */}
        <Route path="/darsafzaar-home" element={<DarsafzaarHome />} />
      <Route element={<ImageCardLayout />}>
        <Route path="/image-card-home" element={<ImageCardsHome />} />
        <Route path="/study" element={<CardStudy />} />
        <Route path="/quiz" element={<CardQuiz />} />
        <Route path="/image-card-guide" element={<CardGuide />} />
        <Route path="/image-card-buy" element={<CardBuy />} />




      </Route>

      {/* blog pages */}
      <Route element={<BlogsLayout />}>
        <Route path="/blogs" element={<BlogsHome />} />
        <Route path="/posts/:slug" element={<PostsByCategory />} />
        <Route path="/search/:slug" element={<SearchResult />} />
        <Route path="/article/:slug" element={<Article />} />
      </Route>
    </Route>
  )
);

function App() {
  const { loading } = useMyContext();
  return (
    <div>
      {loading && <Loading />} <RouterProvider router={router} />{" "}
    </div>
  );
}

export default App;
