import React from "react";
import { AiFillInstagram } from "react-icons/ai";
import { AiOutlineTwitter } from "react-icons/ai";
import { AiFillLinkedin } from "react-icons/ai";
import "./Footer.css";

export default function Footer() {
  return (
    <footer>
      <a href="/" className="footer-logo">
        متن آویس
      </a>
      <ul className="permalinks">
        <li>
          <a href="/#contact">ارتباط با ما</a>
          <a href="/#services">خدمات</a>
          <a href="/#projects">پروژه‌ها</a>
          <a href="/#about">درباره ما</a>
          <a href="/">خانه</a>
        </li>
      </ul>
      <div className="footer-socials">
        <a
          target="blank"
          href="https://www.instagram.com/matn.avis?igsh=MTlsZzYxODY2N2FzaQ=="
        >
          <AiFillInstagram />
        </a>
        <a target="blank" href="https://twitter.com">
          <AiOutlineTwitter />
        </a>
        <a target="blank" href="https://linkedin.com">
          <AiFillLinkedin />
        </a>
      </div>
      <div className="contact-info">
        <p>
        تهران، ولنجک، میدان شهید شهریاری، بلوار دانشجو،
        </p>
        <p>
        دانشگاه شهید بهشتی، دانشکدهٔ معماری و شهرسازی، مرکز نوآوری
        </p>
        <p> ۰۲۱-۲۹۹۰۲۸۷۰</p>
        <p>info@matnavis.com</p>
      </div>
      <div className="footer-copyright">
        <small>همه حقوق متعلق به شرکت مان تحقیق و نظر آویس است &copy;</small>
      </div>
      <div>
        <div>
          <a
            referrerPolicy="origin"
            target="_blank"
            rel="noreferrer"
            href='https://trustseal.enamad.ir/?id=348001&Code=KuKJRNUS0rCvVuOJvHe6OsWCY9wZ20Ud'
          >
            <img
              referrerPolicy="origin"
              alt="logo-enamad"
              src='https://trustseal.enamad.ir/logo.aspx?id=348001&Code=KuKJRNUS0rCvVuOJvHe6OsWCY9wZ20Ud'
              code='KuKJRNUS0rCvVuOJvHe6OsWCY9wZ20Ud'
            />
          </a>
          <img
            referrerPolicy="origin"
            id="rgvjwlaojzpesizprgvjwlao"
            onClick={() =>
              window.open(
                "https://logo.samandehi.ir/Verify.aspx?id=347934&p=xlaoaodsjyoepfvlxlaoaods",
                "Popup",
                "toolbar=no, scrollbars=no, location=no, statusbar=no, menubar=no, resizable=0, width=450, height=630, top=30"
              )
            }
            alt="logo-samandehi"
            src="https://logo.samandehi.ir/logo.aspx?id=347934&p=qftishwlyndtbsiyqftishwl"
          />
        </div>
      </div>
    </footer>
  );
}
