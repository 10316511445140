import React  from "react";
import { Link, useLocation } from "react-router-dom";
import "./BlogsNavLinks.css"

const BlogsNavLinks = ({ categories, showAllLink = false }) => {
  const { pathname } = useLocation();

  return (
    <nav className="blog-navlinks">
      <ul>
        {/* Conditionally render the "همه یادداشت‌ها" link */}
        {showAllLink && (
          <Link to="/blogs">
            <li className={`${pathname === '/blogs' ? 'active' : ''}`}>همه یادداشت‌ها</li>
          </Link>
        )}
        {categories?.map((category) => (
          <Link key={category.slug} to={`/posts/${category.slug}`}>
            <li className={`/posts/${category.slug}` === pathname ? 'active' : ''}>{category.name}</li>
          </Link>
        ))}
      </ul>
    </nav>
  );
};

export default BlogsNavLinks;
