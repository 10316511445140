import React, { useEffect, useState } from "react";
import { MdDateRange } from "react-icons/md";
import PersianDate from "persian-date";
import "./SinglePost.css";
import imageCompression from "image-conversion";
import CitationGenerator from "../CitationGenerator";

const SinglePost = ({ post }) => {
  const [compressedImages, setCompressedImages] = useState([]);

  useEffect(() => {
    const compressImages = async () => {
      const compressedImagesArray = [];
      for (const image of post.images) {
        const compressedImage = await compressImage(image);
        compressedImagesArray.push(compressedImage);
      }
      setCompressedImages(compressedImagesArray);
    };

    if (post.images && post.images.length > 0) {
      compressImages();
    }
  }, [post.images]);

  const compressImage = async (image) => {
    const compressedImage = await imageCompression(image, {
      maxSizeMB: 0.5,
      maxWidthOrHeight: 1024, // Adjust later if the height is not right
    });
    return compressedImage;
  };

  return (
    <article className="single-post">
      <h3>{post.title}</h3>
      <h4>{post.author}</h4>
      <div className="blog-date">
        <span>
          <MdDateRange className="date-icon" />
          {formatDate(post.updatedAt)}
        </span>
      </div>
      {compressedImages.map((compressedImage, index) => (
        <img
          key={index}
          src={compressedImage}
          alt={` ${index + 1}`}
          className="img-fluid"
          loading="lazy"
        />
      ))}
      <div
        className="content"
        dangerouslySetInnerHTML={{ __html: post.content.html }}
      ></div>
      <div>

      <CitationGenerator />
      </div>
    </article>
  );
};

const formatDate = (dateString) => {
  const datePart = dateString.split("T")[0];
  const dateObject = new Date(datePart);
  if (isNaN(dateObject.getTime())) {
    return "";
  }
  const persianDate = new PersianDate(dateObject);
  return persianDate.format(" DD MMMM YYYY", "fa");
};

export default SinglePost;
