import  React  from "react";
import {  QUERY_ONE_POST } from "../../Graphql/Queries";
import SinglePost from "../../components/blogs-components/SinglePost";
import BlogComments from "../../components/blogs-components/BlogComments";
import useQueryPosts from "../../hooks/useQueryPosts";

const Article = () => {
  const {posts, error} = useQueryPosts({query: QUERY_ONE_POST })

  if(error) return <h2 style={{textAlign: 'center'}}>{error}</h2>

  return (
    <>
      {posts?.map((post) => (
        <div key={post.id}>
          <SinglePost key={`post_${post.id}`} post={post} />
          <BlogComments key={`comments_${post.id}`} comments={post?.comments} />
        </div>
      ))}
    </>
  );
};

export default Article;